import { navigate } from '@reach/router';
import Button from 'components/Molecules/Button';
import { routes } from 'constants/routes';
import React from 'react';

const notFound = () => (
	<div className='w-full h-content flex flex-col items-center justify-center'>
		<h1>Podana strona nie istnieje</h1>

		<Button small><p onClick={() => window.close()}>Zamknij</p></Button>
	</div>
);

export default notFound;
